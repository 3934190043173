/// @group Gutenberg_Button
.wp-block {
    &-button {
        border-radius: $border-radius;
        &__link {
            @extend .btn;
            @include button-variant($theme-purple, $theme-purple);
            box-shadow: none;
            &.no-border-radius {
                border-radius: inherit !important;
            }
        }
    }
    &-buttons {
        @include media-breakpoint-down(md) {
            max-width: calc( 100vw - ( #{$spacer} * 2 ) );
            &.is-content-justification-center {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}