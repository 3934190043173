/// @group Gutenberg|Image
.wp-block-image {
    img {
        height: auto;
    }
    @include media-breakpoint-down(md) {
        img {
            width: 100%;
            .wp-block-cover &,
            .wp-block-media-text &,
            .wp-block-column & {
                margin-bottom: 2rem !important;
            }
        }
    }
}