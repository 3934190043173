/// @group Blog
/// includes index and archive template styling
.archive {

}

.category,
.blog {
    .posts-header {
        h1 {
            margin-bottom: 3rem;
        }

        margin-bottom: -5rem;
        @include media-breakpoint-down(lg) {
            margin-bottom: 1rem;
        }
    }

    .blog-posts {
        margin-bottom: 3rem;
    }
}