//@footer
.page-footer {
    margin-top: 60px;
    .footer {
        &-links {

            padding-top: 2rem;
            padding-bottom: 2rem;

            // Colors
            background-color: $theme-green;
            color: $black;
            .nav {
                &-link {
                    font-weight: bold;
                    color: $black;
                }
            }
            &--socket {
                background-color: $theme-purple;
                color: $white;
                .nav {
                    &-item {
                        &+.nav-item {
                            @include media-breakpoint-up(md) {
                                .nav-link {
                                    border-left: 1px solid $white;
                                }
                            }
                        }
                    }
                    &-link {
                        font-weight: normal;
                        color: $white;
                    }
                }
            }

            // Layout
            p,
            .nav {
                margin-bottom: 1rem;
            }
            .nav {
                justify-content: center;
                &-item {
                    padding: .5rem 0;
                    .nav-link {
                        padding-top: 0;
                        padding-bottom: 0;
                        @include on-action {
                            text-decoration: underline;
                        }
                    }
                }
            }
            p {
                text-align: center;
            }

            // Logo
            .footer-logo-wrapper {
                display: block;
                position: relative;
                top: calc(-60px - 2rem);
                margin-bottom: calc(-60px - 2rem);
                text-align: center;
                img {
                    width: 327px;
                    height: auto; // 121px
                }
                & + .nav {
                    margin-top: 1rem;
                    margin-bottom: 0rem;
                }
            }
        }
    }
}