///
/// @group Fonts
///
@import url("//hello.myfonts.net/count/3e7db8");
  
@font-face {
  font-family: "VAG Rounded";
  font-weight: 300;
  font-display: swap;
  src: url('/assets/fonts/VAGRoundedStdThin/font.woff2') format('woff2'), url('/assets/fonts/VAGRoundedStdThin/font.woff') format('woff');
}

@font-face {
  font-family: "VAG Rounded";
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/VAGRoundedStdLight/font.woff2') format('woff2'), url('/assets/fonts/VAGRoundedStdLight/font.woff') format('woff');
}

@font-face {
  font-family: "VAG Rounded";
  font-weight: 600;
  font-display: swap;
  src: url('/assets/fonts/VAGRoundedStdBold/font.woff2') format('woff2'), url('/assets/fonts/VAGRoundedStdBold/font.woff') format('woff');
}

@font-face {
  font-family: "VAG Rounded";
  font-weight: 900;
  font-display: swap;
  src: url('/assets/fonts/VAGRoundedStdBlack/font.woff2') format('woff2'), url('/assets/fonts/VAGRoundedStdBlack/font.woff') format('woff');
}

@font-face {
    font-family: 'Chunk Five';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url('/assets/fonts/chunkfive/chunkfive-webfont.eot');
    src: url('/assets/fonts/chunkfive/chunkfive-webfont.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/chunkfive/chunkfive-webfont.woff') format('woff'), url('/assets/fonts/chunkfive/chunkfive-webfont.ttf') format('truetype');
}