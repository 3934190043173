/// @group AccentBubbles
.with-accent-bubbles {
    &.has-background-dim {
        &:before {
            opacity: .25 !important;
        }
    }
    position: relative;
    .accent-circles {
        @include position( absolute, 0, 0, 0, 0 );
    }
    > * {
        position: relative;
    }
}

@each $color, $hex in $gutenberg-colors {
    .has-#{$color}-background-color {
        .accent-circles .color-1 {
            fill: darken(saturate($hex, 30%), 5%);
        }
        .accent-circles .color-2 {
            fill: lighten($hex, 20%);
            @if $color == 'green' {
                fill: $theme-green-accent;
            }
        }
        .accent-circles .color-3 {
            fill: $white;
        }
    }
}