/// @group List
ul,
ol {
    &.is-style-never-list {
        list-style-type: none !important;
        display: flex;
        flex-wrap: wrap;

        padding-left: 0 !important;

        li {
            flex: 0 0 percentage(1/3);
            @include media-breakpoint-down(md) {
                flex: 0 0 100%;
            }
            color: $theme-purple;
            font-weight: bold;

            display: inline-flex;

            margin-bottom: 1rem;

            &:before {
                content: 'x';
                color: $theme-orange-accent;
                margin-right: 1rem;
                flex-shrink: 0;
            }
        }
    }
}