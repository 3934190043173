///
/// @group Product
///

// Product Tease
.product--tease {
	display: flex;
	flex-direction: column;
	align-self: stretch;

	justify-content: space-between;
	width: 100%;

	box-shadow: 0 0 8px rgba( $dark, 0.01 );

	a {
		text-decoration: none;
	}
	h1,
	h2,
	h3 {
		font-family: $font-family-sans-serif;
	}

    .product {
        &-link {
            color: $theme-orange-accent;
            i {
                font-size: rem( 35 );
            }
        }

        &-tag {
            font-size: rem( 20 );
            vertical-align: baseline;
        }

        &-description {
            text-align: center;
            font-size: 1rem;
            color: $theme-purple;

            margin-bottom: 1rem;

            min-height: rem( 27 );

            * {
                color: inherit;
                font-size: inherit;
                display: inherit;
                margin: 0;
                padding: 0;
            }
        }

        &-header {
            padding: 1rem;
            text-align: center;
            .product-title {
                font-weight: bold;
                font-size: rem( 28 );
                line-height: rem( 28 );
            }
        }

        &-content {
            background-color: $white;
            border-radius: $border-radius-lg;
            padding: 1rem;
        }

        &-tags {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-image-wrapper {
            position: relative;
            .product-tags {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
            }
            figure {
                width: 100%;
                margin-top: 1rem;
                margin-bottom: 1rem;

                text-align: center;

                img {
                    width: 100%;
                    max-width: rem( 250 );
                    height: auto;
                }
            }
        }
    }
}

// simple product specific styles
.product-type-simple {
    .price:before,
    .cart .quantity:before {
        font-size: 1rem;
        font-weight: 700;
        color: $primary;
        display: block;
        width: 4.22222rem;
        flex-shrink: 0;
    }

    .price {
        display: inline-flex;
        align-items: center;
        &:before {
            content: 'Price';
            .products & {
                display: none;
            }
        }
    }
    .cart {
        display: flex;
        flex-wrap: nowrap;
        .quantity {
            display: inline-flex;
            align-items: center;
            &:before {
                content: 'Qty.';
            }
        }
        .form-control {
            width: 5.55rem;
            padding: .5rem;
            margin-right: .5rem;
        }
        button {
            flex-grow: 1;
        }

        .wholesale-products-wrapper & {
            justify-content: flex-end;
            .quantity:before {
                display: none !important;
            }
        }
    }
}

.wholesale-products-wrapper .not-purchasable {
    .button {
        border-radius: $border-radius !important;
    }
}

// Add to cart button
.single_add_to_cart_button {
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: rem( 45 );

    position: relative;

    transition: all 0.3s;

    background-color: $white;
    border-radius: $border-radius !important;

    box-shadow: 0 0 8px rgba( $theme-purple, 0.31 );

    padding: 0.5rem 1rem;

    color: $theme-purple;
}
.product-add-to-cart {
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: rem( 45 );

    position: relative;

    transition: all 0.3s;

    background-color: $white;
    border-radius: $border-radius;
    margin-top: 1rem;

    box-shadow: 0 0 8px rgba( $theme-purple, 0.31 );

    padding: 0.5rem 1rem;

    color: $theme-purple;

    > * {
        font-size: 1rem !important;
        line-height: 1 !important;
        background-color: transparent !important;
        padding: 0 !important;
        border: none !important;
        text-decoration: none !important;
        transition: all 0.3s;
    }

    a.button {
        position: unset !important;
        color: inherit !important;
        font-weight: normal !important;
        background-color: transparent !important;
        margin: 0 0.35rem 0 0.75rem !important;
        text-transform: none !important;
        font-family: $font-family-sans-serif !important;

        &:before {
            content: '';
            display: block;
            @include position( absolute, 0, 0, 0, 0 );
        }
    }

    @include on-action {
        background-color: $theme-purple;
        > *,
        a.button {
            color: $white !important;
        }
    }

    // Added to cart 'view cart' button
    a.added_to_cart {
        display: block !important;
        position: absolute;
        top: calc(100% + .5rem);
        text-align: center;
        padding: .5rem 1rem !important;
        border-radius: $border-radius;
        background-color: $theme-green-accent !important;
        color: $white !important;

        @include on-action() {
            background-color: darken( $theme-green-accent, 5% ) !important;
        }
    }
}

// Product Single Page
// Product Quick View Modal
.product.product--single,
.quick-view .product {

    // Layout
    display: flex !important;
    flex-wrap: wrap;

    align-items: center;

    width: 100%;

    .product-header {
        flex: 0 0 100%;
        width: 100%;
    }
    .entry-images,
    > .woocommerce-product-gallery {
        flex: 0 0 48%;
        width: 48%;
        float: none;
        .images {
            width: 100%;
        }
    }
    .entry-summary {
        flex: 0 0 48%;
        margin-left: auto;
        float: none !important;
    }

    @include media-breakpoint-down(lg) {
        .entry-images,
        .entry-summary {
            flex: 0 0 100%;
            width: 100%;
        }
    }

    @include media-breakpoint-down(md) {
        .entry-images .images {
            width: 80%;
            margin: 0 auto;
        }
    }

    .quick-view-detail-button {
        margin-left: auto;
        @extend .btn;
        @extend .btn-primary;
    }

    // Styles
    .product {
        &-header {
            margin: 4rem 0 2rem;
            text-align: center;
            color: $theme-purple;
        }
        &-title {
            font-size: rem(48);
            line-height: rem(73);
        }
        &-description {
            font-size: rem(32);
            line-height: rem(46);
        }

        @include media-breakpoint-down(md) {
            &-title {
                font-size: rem(32);
                line-height: rem(44);
            }
            &-description {
                font-size: rem(18);
                line-height: rem(24);
            }
        }

        &-content {
            margin-bottom: 1.5rem;
        }

        &-information {
            flex: 0 0 100%;
            width: 100%;
        }
    }

    // Price
    .price {
        color: $theme-green-accent !important;
    }

    // Star Rating HTML
    .star-rating {
        float: none;
        color: $yellow;
        letter-spacing: 1rem;
        width: auto;
        margin-bottom: 1.5rem;
        @include media-breakpoint-down(md) {
            width: 7.3em;
            margin: 0 auto 1.5rem;
            font-size: 2rem;
        }
    }

    // Variations Table
    .variations {
        color: $theme-purple;
        margin-bottom: 0 !important;
        .label {
            vertical-align: top !important;
            padding: .5rem 0 0 !important;
        }
        .value {
            padding-bottom: 1rem;
            select {
                @extend .form-select;
            }
            select,
            .form-select {
                text-align: center !important;
                color: $theme-purple;
                border-color: $theme-purple;
                option {
                    text-align: center;
                }
                & + a {
                    margin-top: 1rem;
                }
            }

            @include media-breakpoint-down(md) {
                padding-left: 1.5rem;
                select {
                    padding: .5rem 3rem .5rem 1rem;
                }
            }
        }
    }

    // Layout Mobile
    @include media-breakpoint-down(md) {
        .entry-summary {
            display: flex;
            flex-direction: column;
            > *  {
                order: 2;
            }
            .star-rating {
                order: 0;
            }
            .variations_form {
                order: 1;
            }
        }
    }

    // Pricing / Add to cart button
    .single_variation_wrap {
        // Price
        .woocommerce-variation-price {
            .price {
                display: flex;
                align-items: center;

                margin-bottom: 1rem;

                &:before {
                    content: 'Price';
                    font-size: rem(18);
                    font-weight: 700;
                    color: $theme-purple;
                    display: block;
                    width: rem(76);
                }
                .amount {
                    color: $theme-green-accent;
                }
            }
        }
        // Add to cart
        .woocommerce-variation-add-to-cart {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;

            .quantity {
                margin-right: .5rem;
                display: flex;
                align-items: center;

                // margin-left: rem(76);
                &:before {
                    content: 'Qty.';
                    font-size: rem(18);
                    font-weight: 700;
                    color: $theme-purple;
                    display: block;
                    width: rem(76);
                }
                .input-text {
                    @extend .form-control;
                }
                .form-control {
                    width: rem(100);
                    padding: .5rem;
                }
            }

            button.single_add_to_cart_button {
                float: none;

                padding: .75rem 1rem;

                width: 100%;
                flex: 1 1 100%;
                font-weight: 400;

                border-radius: $border-radius;
                background-color: $theme-purple;

                display: flex;
                align-items: center;
                justify-content: center;

                &:before {
                    font-family: 'Font Awesome 5 Free';
                    font-weight: 900;
                    content: "\f07a";
                    margin-right: .5rem;
                }
            }
        }
    }

    // Meta 
    .product_meta {
        > span {
            margin-right: 1rem;
            font-size: rem(16);
        }
        a {
            // @extend .badge;
            // @extend .rounded-pill;
            // @extend .bg-primary;
        }
    }
}

// Quick View Product Mobile
@include media-breakpoint-down(md) {
    .quick-view .product {
        font-size: 80%;
        .woocommerce-product-gallery {
            flex: 0 0 60%;
            width: 60%;
            margin: 0 auto 1rem;
            img {
                width: 100%;
                height: auto;
            }
        }
        .entry-summary {
            display: block !important;
            .product_meta {
                display: none;
            }
        }
        .quick-view-detail-button {
            width: 100%;
        }
    }
}

.product-infographic {
    background-color: $theme-purple;
    color: $white;
    padding: 5rem 0 3rem;
    margin: 4rem 0;

    &-details {
        list-style-type: none;
        counter-reset: custom-counter;

        padding-left: 0;
        padding-right: 0;

        li {
            display: flex;
            align-items: center;

            margin-bottom: 1.5rem;

            counter-increment: custom-counter;
            &:before {
                content: counter(custom-counter);

                display: block;

                $size: rem-val(40);
                width: $size;
                height: $size;

                border-radius: 50%;

                display: inline-flex;
                align-items: center;
                justify-content: center;

                flex-shrink: 0;

                color: $white;
                background-color: $theme-orange-accent;
                font-size: 1rem;
                line-height: 1rem;

                margin-right: .5rem;
            }

        }
    }
}

.product-buy-online {
    color: $theme-purple;
    margin: 5rem 0;

    ul {
        margin-top: 4rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-down(md) {
            li {
                flex: 0 0 50%;
                width: 50%;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}