// Gutenberg Configuration
// Expose CSS variables
:root {
    @each $key, $color in $gutenberg-colors {
      --#{$key}: #{$color};
    }
}

/// @group Mixins
@mixin block($slug) {
    .wp-block-#{$slug} {
        @content;
    }
}
@mixin media-breakpoint-wp {
    @media screen and (max-width: 781px) {
        @content;
    }
}

/// @group Editor
// Add Editor classes
// Note -- these need to match the slug names of the
// theme color configuration...
@each $key, $color in $gutenberg-colors {
    .has-#{$key}-color{
        color: $color !important;
    }
    .has-#{$key}-background-color{
        background-color: $color !important;
        $_color: choose-contrast-color( $color );
        color: $_color;
        [class*='__inner-container'] {
            color: $_color;
        }
    }
}

// e.g. .container-fluid
.alignfull {
    @include make-container();
}

// e.g. .container
.alignwide {
    @include make-container();

    @each $breakpoint, $container-max-width in $container-max-widths {
    
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            max-width: $container-max-width;
        
            // Extend each breakpoint which is smaller or equal to the current breakpoint
            $extend-breakpoint: true;
        
            @each $name, $width in $grid-breakpoints {
                @if ($extend-breakpoint) {
                    // Once the current breakpoint is reached, stop extending
                    @if ($breakpoint == $name) {
                        $extend-breakpoint: false;
                    }
                }
            }
        }
    }
}

/// @group Blocks
@import 
    'accent-border',
    'accent-bubbles',
    'button',
    'columns', 
    'cover',
    'embed',
    'image',
    'list',
    'media-text',
    'spacer';

// @Devnote: Post Content is wrapped for specificity
// with `#entry-content`
#entry-content {
   
}
