///
/// @group Woocommerce
///
// Shop Wrapper
.shop {

    // Colors
    &-wrapper {
        padding-bottom: 3rem;

        background-color: $theme-purple-light;
        // Acccent Border
        & + .accent-border {
            color: $theme-purple-light;
            margin-bottom: 3rem;
        }
    }
}

// Shop Filters
.shop-filters {
    text-align: center;
    top: rem(80);
    z-index: 1001;
    @include media-breakpoint-down(lg) {
        position: relative !important;
        top: auto;
    }

    &-dropdown-toggle {
        background-color: $white;
        color: $body-color;
        padding: .5rem 1rem;
        border-radius: $border-radius;
        display: flex;
        justify-content: space-between;
        align-items: center;

        text-decoration: none;
        font-weight: bold;
        & + .dropdown-menu {
            width: 100%;
        }
    }
}
.nav.nav--category-filters {
    // justify-content: center;
}
.nav.nav--shop-filters {
    background-color: $white;
    border-radius: $border-radius;
    display: inline-flex;

    padding: .5rem .75rem;

    box-shadow: 0 0 8px rgba($theme-purple, .1);

    span.nav-link {
        font-weight: bold;
    }
    a.nav-link {
        color: $dark;
        margin-left: .5rem;
        transition: all .3s;
        border-radius: $border-radius;

        @mixin active {
            background-color: $theme-purple;
            color: $white;
        }
        &.active {
            @include active;
        }
        @include on-action {
            @include active;
        }
    }
}

// Shop Loop
.loop {
    display: flex;
    flex-wrap: wrap;

    margin-top: 4rem;
    @include media-breakpoint-down(md) {
        margin-top: 2rem;
        padding-left: $spacer;
        padding-right: $spacer;
    }

    // Product Loop Item
    &-item {
        align-self: stretch;

        display: flex;

        margin-bottom: 4rem;

        $size: calc(#{percentage(1/3)} - 2rem);
        flex: 0 0 $size;
        @include media-breakpoint-up(xl) {
            margin-right: 3rem;
            &:nth-child(3n + 3) {
                margin-right: 0;
            }
        }
        @include media-breakpoint-down(xl) {
            flex: 0 0 calc(50% - 2rem);
            margin-right: 2rem;
            &:nth-child(even) {
                margin-right: 0;
            }
        }
        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            margin-right: 0 !important;
            margin-bottom: 2rem;
        }
        
    }
}

// Woocommerce Store Notice
.woocommerce-store-notice {
    &-wrapper {
        position: relative;
        background-color: $primary;
        color: $white;
        width: 100%;
        padding: .75rem 1rem !important;
    }

    z-index: 1 !important;

    position: relative !important;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;

    text-align: center;
    font-size: rem(16) !important;

    display: block !important;

    box-shadow: none !important;

    .admin-bar & {
        top: auto !important;
    }

    padding: 0 !important;
    margin-bottom: 0 !important;

    a {
        color: $white !important;
        text-decoration: underline;
    }

    @include media-breakpoint-down(md) {
        &-wrapper {
            padding: .5rem 1rem !important;
        }
    }
}