.tease {

    padding: 0 1.5rem;

    &--post {
        figure {
            border-radius: $border-radius-lg;
            overflow: hidden;
            padding-top: 100%;

            margin-bottom: 1.5rem;

            position: relative;
            img {
                @include position(absolute,0,0,auto,0);
                width: 100%;
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }

        .tease__link {
            text-decoration: none;
            font-size: rem(28);
            line-height: rem(35);
            text-align: center;

            transition: all .3s;

            @include on-action {
                // text-decoration: underline;
                color: $theme-green-accent;
            }
        }
        
        .tease__excerpt {
            display: none;
        }
    }

    &--product {

    }
}