/// @group Woocommerce
/// includes up-sells, cross-sells and related products

// cross sells
.cross-sells {
    .products {
        margin-top: 1rem !important;
    }
    .woocommerce-loop-product {
        &__link {
            display: flex;
            flex-direction: column-reverse;

            // img {
            //     max-width: 200px;
            //     height: auto;
            //     margin: 0 auto 1rem !important;
            // }
        }
        &__title {
            font-family: $font-family-sans-serif !important;
            text-align: center;
            font-weight: bold;
        }
    }
}