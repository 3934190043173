///
/// @group Animations
///

// Accent Circles - general movement
.drifts {
    animation-duration: 40s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

// direction, dx, dy
$distance: 50%;
$directions: (
    ( 'north', 0, #{$distance} ),
    ( 'northeast', #{$distance}, -#{$distance} ),
    ( 'east', #{$distance}, 0 ),
    ( 'southeast', #{$distance}, #{$distance} ),
    ( 'south', 0, #{$distance} ),
    ( 'southwest', -#{$distance}, #{$distance} ),
    ( 'west', -#{$distance}, 0 ),
    ( 'northwest', -#{$distance}, -#{$distance} ),
);
@each $dir, $dx, $dy in $directions {
    @keyframes drifts-#{$dir} {
        0% {
            transform: translate(0,0);
        }
        50% {
            transform: translate($dx, $dy);
        }
        100% {
            transform: translate(0,0);
        }
    }
    .drifts--#{$dir} {
        animation-name: drifts-#{$dir};
    }
}