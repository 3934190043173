/// @group Carousel
.carousel {

    padding-left: 3rem;
    padding-right: 3rem;

    @include media-breakpoint-down(md) {
        .wp-block-column & {
            padding-left: 0;
            padding-right: 0;
        }
    }

    // Testimonials
    &--testimonials {
        margin: 2rem 0;
        @include media-breakpoint-down(md) {
            margin: 1rem 0 3rem;
        }
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: -1rem;
            left: -1rem;
            width: 3rem;
            height: 3rem;
            background-image: url(/assets/images/double-quote@2x.png);
            background-size: contain;
            background-repeat: no-repeat;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .has-light-gray-background-color & {
            &:before {
                display: none;
            }
        }
    }
	
	// Equal height slides
	&--equal-height {
        .active,
        .carousel-item-next {
            display: flex;
            align-items: center;
        }
	}

    // Indicator
    &-progress {
        margin-top: 3rem;

        @include media-breakpoint-down(md) {
            margin-top: 1rem;
        }

        .progress-bar {
            width: 0%;
            transition: width 0s;

            border-top-right-radius: 1rem;
            border-bottom-right-radius: 1rem;
        }

        // Animations
        @keyframes progressExpand {
            from {
                width: 0%;
            }
            to {
                width: 100%;
            }
        }
        &.animated .progress-bar {
            width: 100%;
            animation: progressExpand 12s linear;
        }

        // Colors
        background-color: $theme-purple-light;
        .progress-bar {
            background-color: $theme-purple;
        }

        // Should we do this for each...?
        .has-green-background-color & {
            background-color: $white;
            .progress-bar {
                background-color: $theme-green-pastel;
            }
        }
    }
}