.skipNav {
	position: absolute;
	left: -10000px;
	font-size: 20px;
	z-index: 1000;
	
	&:focus {
	    left: 0px;
	}
}

@media ( prefers-reduced-motion: reduce ) {
  *,
  ::before,
  ::after {
    animation-duration: 0.001s !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001s !important;
  }
}