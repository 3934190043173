/// On-action Mixin
/// @description Combine hover, active and focus states into one css declaration
/// @group _mixins
@mixin on-action( $context: false ) {
	@if $context != false {
		#{$context},
		&:hover,
		&:active,
		&:focus {
			outline: none;
			@content;
		}
	} 
	@else {
		&:hover,
		&:active,
		&:focus {
			outline: none;
			@content;
		}
	}
	
}