// @gravity forms
.gform_wrapper {

	.gform_heading {
		margin: 2rem 0;
		.gform_title {
			font-size: rem(48);
			color: $theme-purple;
			margin-bottom: 2rem;
		}
		.gform_description {
			font-size: rem(32);
			line-height: rem(42);
		}
	}

	// @local

	.gform_body {
		.gform_fields {

			// radio and checkbox
			fieldset.gfield {
				flex-direction: column;
			}

			.gfield {
				display: flex;
				flex-direction: column-reverse;

				// @label
				.gfield_label {
					font-weight: normal;
					margin-top: .25rem;
					// @required
					.gfield_required {
						color: $red;
					}
				}
				&.label_hidden {
					.gfield_label {

					}
				}

				// @section
				&.gsection {
					.gsection_title {
					}
				}

				// @input container
				.ginput_container {
					// margin-top: 0px !important;
					// padding-top: 8px;

					// @general input
					input[type="text"],
					input[type="email"],
					input[type="number"],
					input[type="tel"],
					input[type="url"],
					select,
					textarea {
						border-radius: $border-radius;
						border: 1px solid $primary;

						font-size: rem(18);

						padding: rem(12);

						&:focus,
						&:focus-visible {
							outline: none;
							box-shadow: 0 0 0 4px rgba($primary, .2);
						}
					}
					// @select
					select {
						height: rem(48);
					}
					// @multiple - select
					[multiple="multiple"] {
						option {
						}
					}
					// @type="file"
					[type="file"] {
					}
					// @radio
					&.ginput_container_radio {
						ul.gfield_radio li {
						}
						// radio base styles
						input[type="radio"] {
						}
						// styling for custom radio unchecked
						input[type="radio"]+label {
							&:before {
							}
							@include on-action() {
								&:before {
								}
							}
						}
						// styling for custom radio checked
						input[type="radio"]:checked+label {
							&:before {
							}
							&.accent_blue {
								&:before {
								}
							}
							&.accent_green {
								&:before {
								}
							}
						}
					}// @radio_end
					// @checkbox
					&.ginput_container_checkbox {
						ul.gfield_checkbox li {
						}
						//checkbox with before text
						input[type="checkbox"] {
						}
						// styling for custom checkbox unchecked
						input[type="checkbox"]+label {
							&:before {
							}
							@include on-action() {
								&:before {
								}
							}
						}
						// styling for custom checkbox checked
						input[type="checkbox"]:checked+label {
							&:before {
							}
							&.accent_blue {
								&:before {
								}
							}
							&.accent_green {
								&:before {
								}
							}
						}
					}// @checkbox_end

					// @complex input - 'custom input type' styling
					&.ginput_complex {
						margin-bottom: -16px;
						> span {
							margin-bottom: 1.5rem;

							&.address_line_1 {
								flex: 0 0 50%;
								padding-right: 1%;
							}
							&.address_line_2 {
								flex: 0 0 50%;
								padding-left: 1%;
							}
							label {
							}
						}
					}
				}
			}
		}
	}//@gform_body
	.gform_footer, .gform_page_footer {
		input[type="submit"], [type="button"], .btn--gform {
			@extend .btn;
			@include button_variant($theme-purple, $theme-purple);
		}
	}// @gform_footer

	// @errors / validation
	&.gform_validation_error {
		form {
			.gform_body {
				.gform_fields {
					.gfield_error {
						input {
						}
						.validation_message {
						}
					}
				}
			}
		}
	}// @gform_validation_error

	.validation_error {
	}// @validation_error


}

// Confirmation
.gform_confirmation{
	&_wrapper {
		background-color: $theme-purple-light;
		padding: 1rem 1.5rem;
		border-radius: $border-radius;
		font-size: 1.25rem;
	}
	&_message {
		
	}
}

/// google recaptcha logo
.grecaptcha-badge {
	// hide
	display: none !important;
	
	right: auto !important;
	left: 1rem !important;
	width: 69px !important;
	transition: all .3s !important;

	@include on-action() {
		width: 256px !important;
	}
}