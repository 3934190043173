/// @group Modal
.modal {
    .btn-close {
        position: fixed;
        top: .25rem;
        right: .25rem;

        width: 2rem;
        height: 2rem;

        padding: 0;

        // svg bg compilation bugfix
        background: none !important;
        &:before {
            content: "\f00d";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 2rem;
            line-height: 2rem;
        }
    }
    &-backdrop {
        &.show {
            opacity: .75;
        }
    }
    &--signup-promo {
        .modal-content {
            overflow: hidden;
            overflow: hidden;
            background-image: url(/assets/images/baby-heads-bg-green@2x.jpg);
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
    &--video {
        .modal-dialog {
            max-width: none;
            width: 100vw;
            height: 100vh;
            padding: 3rem;
            margin: 0 auto;

            @include media-breakpoint-down(md) {
                padding: 1rem;
            }
        }

        .modal-content {
            width: 100%;
            height: 100%;

            @include media-breakpoint-up(lg) {
                max-width: 80vw;
                margin: 0 auto;
            }

            display: flex;
            justify-content: center;

            background-color: transparent;
            border: none;
            box-shadow: none;
        }
    }
}