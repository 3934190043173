/// @group Gutenberg|Columns
.wp-block-columns {
    .wp-block-column {
        @include media-breakpoint-down(lg) {
            &:empty {
                flex: 0 1 auto !important;
            }
        }
        @include media-breakpoint-down(md) {
            padding-left: $spacer;
            padding-right: $spacer;
        }
    }
}