#gdpr {
    position: sticky;
    bottom: 0;
    z-index: 1090;
    display: block;
    
    background-color: $theme-purple-light;
    box-shadow: 0px -1px 6px rgba($black, .1);

    padding: .75rem 0;

    font-size: .8rem;
    .d-flex > * {
        margin: 0 1rem;
    }

    @include media-breakpoint-down(md) {
        .container {
            padding-left: 0;
            padding-right: 0;
        }
        .gdpr-actions {
            flex-shrink: 0;
        }
    }
}