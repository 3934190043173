.instagram-feed {

    background-color: $gray-100;
    padding: 2rem 0 5rem;

    margin-bottom: -3.5rem;

    &__track {
        display: flex;
        flex-wrap: nowrap;
        width: 100vw;
        overflow: scroll;

        padding-left: 4rem;
    }

    @include media-breakpoint-down(md) {
        .feed {
            display: none;
        }
        &__track {
            padding-left: 0;
            width: auto;
            .nav.nav--social-follow {
                flex-direction: row;
                margin: 0 auto;
                width: 100%;
                justify-content: space-evenly;
            }
        }
    }

    // Unused
    // &-images {
    //     list-style: none;
    //     display: flex;
    //     flex-wrap: nowrap;
    // }

    // &-image {
    //     flex-shrink: 0;
    //     margin-right: 1.5rem;
    //     img {
    //         border-radius: $border-radius-lg;
    //     }
    // }

    // Plugin Functionality
    .feed {
        margin-left: 1rem;
        #error {
            display: none;
        }
    }
    #sb_instagram {
        .sb_instagram_header {
            display: none;
        }

        #sbi_images {
            display: flex;
            flex-wrap: nowrap;
            float: none;

            .sbi_item {
                width: auto !important;
                flex-shrink: 0;
                padding: 0 !important;
                margin-right: 1.5rem !important;

                .sbi_photo_wrap {
                    .sbi_photo {
                        border-radius: $border-radius-lg;
                        height: rem(300) !important;
                        width: rem(300) !important;
                    }
                }
            }
        }
    }
}