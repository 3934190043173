/// @group Gutenberg|MediaText
.wp-block-media-text {
    padding: 3rem 0;

    &__content {
        padding: 0 4rem 0 2rem;
        .has-media-on-the-right & {
            padding: 0 2rem 0 4rem;
        }
    }

    &__media {
        padding: 0 2rem 0 4rem;
        .has-media-on-the-right & {
            padding: 0 4rem 0 2rem;
        }
        img {
            width: 100%;
            height: auto;
        }
    }

    @include media-breakpoint-down(md) {
        &__content,
        &__media {
            padding: 0 $spacer !important;
            img {
                margin-bottom: 2rem;
            }
        }
        &--image-last {
            display: flex !important;
            flex-direction: column-reverse !important;
        }
    }
}