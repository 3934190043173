/// @group Bootstrap|Nav
.nav {
    &--social {
        .nav-link {
            font-size: rem(22);
            padding: 1.5rem 1rem;
        }

        &-follow {
            font-size: rem(24);
            flex-direction: column;
            justify-content: space-between;
        }
    }
}

/// @group Woocommerce|Account
// Account Navigation
.woocommerce-MyAccount-navigation {
    > ul {
        @extend .nav;
        @extend .nav-pills;
        flex-direction: column;
        li {
            @extend .nav-item;
            a {
                @extend .nav-link;
            }
            &.is-active a {
                @extend .active;
            }
        }
    }
}