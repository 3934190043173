// @.navbar - ref [v5]
.navbar {

    $navbar-breakpoint: lg;

    background-color: transparent;

    position: sticky;
    top: 0;

    .admin-bar & {
        top: 32px;
    }

    padding: 0;
    > .container-fluid {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    &--menu-open {
        // Offset for accent border
        margin-bottom: -3rem;
    }

    // Stacking
    > .container-fluid,
    > .navbar-collapse {
        position: relative;
    }
    > .container-fluid {
        z-index: 2;
        align-items: center;
    }

    // Colors
    > .container-fluid {
        position: relative;
        &:before {
            content: '';

            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            background-color: $white;
            box-shadow: 0px 3px 6px rgba(#000, .1);
        }
        > * {
            position: relative;
        }
    }

    // Layout
    > .container-fluid {
        .navbar-brand {
            flex: 0 0 0;
        }
        .navbar-static {
            flex: 1 0 0;
        }
    }

    // Logo
    &-brand {
        padding: 0;
        margin-right: 0;
        img {
            // defaults
            width: 7.75rem;
            height: auto;
            transform-origin: top center;
            transition: all .3s;
        }
    }
    html[data-scroll="0"] & {
        .navbar-brand img {
            transform: scale(2);
            @include media-breakpoint-down($navbar-breakpoint) {
                transform: scale(1.15) translateY(50%);
            }
        }

    }

    // Menu Button
    &-toggler {
        border: none;

        .navbar &,
        .navbar-light & {
            color: #000;
            @include on-action {
                color: $primary;
            }
        }

        svg {
            rect {
                transition: all .3s;
            }
        }
        &[aria-expanded="true"] {
            svg {
                rect {
                    transform-origin: left center;
                    &:nth-child(1) {
                        transform: rotate(45deg) translate(-3px, 0px);
                    }
                    &:nth-child(2) {
                        opacity: 0;
                    }
                    &:nth-child(3) {
                        transform: rotate(-45deg) translate(-3px, 1px);
                    }
                }
            }
        }
    }

    // Persistent Menu
    &-static {
        display: flex;
        // Menu button wrapper
        &--start {}

        // Searc/Cart Wrapper
        &--end {
            justify-content: flex-end;
        }

        @include media-breakpoint-down($navbar-breakpoint) {
            &--end {
                .nav-item {
                    & + .nav-item {
                        margin-left: .5rem;
                    }
                    .nav-link {
                        padding: .25rem;
                    }
                }
            }
        }
    }

    // Dropdown Menu
    &-collapse {

        @include media-breakpoint-down($navbar-breakpoint) {
            max-height: calc(100vh - 51.7px - .5rem);
            overflow: scroll;
            padding-bottom: 5vh;
        }

        .container-fluid {
            @include media-breakpoint-up($navbar-breakpoint) {
                padding: 5rem 3rem;
            }
            @include media-breakpoint-down($navbar-breakpoint) {
                padding: 3rem 1rem;
            }
        }

        // Colors
        &__content {
            background-color: $theme-purple-light;
            &+.accent-border {
                color: $theme-purple-light;
                transform: translateY(-1px);
            }
        }

        // Menu Wrapper
        &-start {

        }

        // Social
        &-end {
            padding-top: 1.5rem;
            
            p,
            ul {
                font-size: rem(24);
            }
            p {
                text-align: center;
            }
            ul {
                justify-content: center;
                margin-bottom: 1rem;
                li {
                    margin-bottom: 0;
                    a {
                        padding: 0 2rem;
                    }
                }
            }
        }

        .navbar-nav {
            position: relative;
            // Top Level Links
            > .nav-item {
                & + .nav-item {
                    margin-top: .5rem;;
                }

                position: unset;

                .dropdown-toggle,
                > .nav-link {
                    color: $black;
                    font-size: rem(24);
                    font-weight: bold;

                    text-transform: lowercase;

                    border-radius: $border-radius;
                    padding: .75rem 1.5rem;

                    @include media-breakpoint-up($navbar-breakpoint) {
                        width: 40%;
                    }

                    transition: all .3s;
                    &[aria-expanded="true"],
                    &:hover,
                    &:focus,
                    &:active {
                        color: $theme-purple;
                        background-color: $white;
                    }

                    &:after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    @include media-breakpoint-up($navbar-breakpoint) {
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: calc(40% + 1.5rem);
                    }

                    @include media-breakpoint-down($navbar-breakpoint) {
                        margin-top: .5rem;
                        .dropdown-item--heading {
                            display: none;
                            &+.dropdown-item {
                                margin-top: 0;
                            }
                        }
                    }

                    border-radius: $border-radius;
                    padding: 1.5rem 2.25rem;

                    border: none;

                    // Dropdown menu items / placeholder image
                    @include media-breakpoint-up($navbar-breakpoint) {
                        &.show {
                            display: flex;
                        }
                        justify-content: space-between;
                        flex-wrap: nowrap;

                        &-items {
                            flex: 0 1 50%;
                            width: 50%;
                            padding-right: 1.5rem;
                        }
                        &-image-wrapper {
                            flex: 1 1 50%;
                            width: 50%;

                            text-align: right;

                            img {
                                width: 100%;
                                max-width: rem(250);
                                height: auto;
                                border-radius: $border-radius-lg;
                            }
                        }
                    }
                    @include media-breakpoint-down($navbar-breakpoint) {
                        &-image-wrapper {
                            display: none;
                        }
                    }
                }

                .dropdown-item {
                    font-size: rem(18);
                    &--heading {
                        font-size: rem(24);
                        font-weight: bold;
                        color: $black !important;
                        text-decoration: none !important;
                        text-transform: lowercase;
                    }

                    white-space: pre-wrap;

                    background-color: transparent !important;

                    &.active {
                        color: $theme-purple;
                        font-weight: bold;
                    }
                    @include on-action {
                        background-color: initial;
                        color: $theme-purple;
                        text-decoration: underline;
                    }

                    padding: 0;
                    &+.dropdown-item {
                        margin-top: 1rem;
                    }
                }
            }
        }

    }

    // Woo
    #navbar-cart-toggler {
        position: relative;

        #navbar-cart-count {
            display: block;
            text-align: center;
            line-height: 1.1rem;

            width: 1rem;
            height: 1rem;
            border-radius: 50%;

            font-size: .7rem;

            background-color: $theme-green-accent;
            color: $white;
            
            position: absolute;
            top: 0;
            right: 0;

            @include media-breakpoint-down(lg) {
                top: -.25rem;
                right: -.25rem;
            }

            &.empty {
                opacity: 0;
            }
        }
    }

    // Search Form
    &-search-form {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 1rem;

        z-index: 1099;

        background-color: $theme-purple-light;

        transition: all .3s;
        transform: translateY(-100%);
        &:focus-within {
            transform: translateY(0);
        }

        .form-control {
            margin-right: 1rem;
            font-size: rem(24);

            border-color: $theme-purple;

            @include placeholder {
                color: darken($theme-purple-light, 40%);
            }
        }

        @include media-breakpoint-down(md) {
            .form-control {
                font-size: rem(18);
                padding: 0.25rem 0.5rem !important;
            }
            .btn {
                padding: 0.25rem 0.5rem !important;
                font-size: 0.875rem;
                border-radius: 0.25rem;
            }
        }
    }
}
