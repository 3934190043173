///
/// @group Collapse
///
.collapse {

    // Wrapper used in product single template
    &-product {
        display: block;

        padding: 1.5rem 2rem;

        margin-bottom: 1.5rem;
        
        border: 1px solid $theme-purple;
        border-radius: $border-radius-lg;

        @include media-breakpoint-down(md) {
            padding: 1rem 1.5rem;
        }
    }

    &-toggle {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        font-family: 'Chunk Five';
        color: $theme-purple;

        font-size: rem(36);
        line-height: rem(48);

        @include media-breakpoint-down(md) {
            font-size: rem(24);
            line-height: rem(36);
        }

        // Unset button/a styles
        padding: 0;

        text-align: left;
        text-decoration: none !important;
        border: none;
        background-color: transparent;

        i {
            color: $theme-purple;
            transition: all .3s;
        }
        &[aria-expanded="true"] {
            i {
                color: $theme-orange-accent;
                &:before {
                    content: "\f056";
                }
            }
        }

        &-wrapper {
            > *:not(.collapse-toggle) {
                margin-bottom: 0;
                font-size: inherit;
                line-height: inherit;
            }
        }
    }

    &-content {
        margin-top: 1.5rem;
    }
}