/// @group Blockquote
blockquote {
    font-size: rem(24);
    line-height: rem(45);
    & + figcaption {
        font-size: rem(24);
        line-height: rem(45);
        font-weight: bold;
    }

    @include media-breakpoint-down(md) {
        font-size: 1rem;
        line-height: 1.3;
        & + figcaption {
            font-size: 1rem;
            line-height: 1.3;
        }
    }
}