// Fonts
@import 
    'fonts';

// Bootstrap Source
// This is a gutenberg project, so we'll pick
// and choose the BS modules we want to use.
// scss-docs-start import-stack
@import 
    '~bootstrap/scss/functions', 
    '~bootstrap/scss/variables',
    'bootstrap.scss',
    '~bootstrap/scss/mixins', 
    '~bootstrap/scss/utilities', 
    '~bootstrap/scss/root', 
    '~bootstrap/scss/reboot', 
    '~bootstrap/scss/type', 
    '~bootstrap/scss/images', 
    '~bootstrap/scss/containers', 
    '~bootstrap/scss/grid', 
    // '~bootstrap/scss/tables', 
    '~bootstrap/scss/forms', 
    '~bootstrap/scss/buttons', 
    '~bootstrap/scss/transitions', 
    '~bootstrap/scss/dropdown', 
    '~bootstrap/scss/button-group', 
    '~bootstrap/scss/nav', 
    '~bootstrap/scss/navbar', 
    '~bootstrap/scss/card', 
    '~bootstrap/scss/accordion', 
    '~bootstrap/scss/breadcrumb', 
    '~bootstrap/scss/pagination', 
    '~bootstrap/scss/badge', 
    '~bootstrap/scss/alert', 
    '~bootstrap/scss/progress', 
    '~bootstrap/scss/list-group', 
    '~bootstrap/scss/close', 
    // '~bootstrap/scss/toasts', 
    '~bootstrap/scss/modal', 
    '~bootstrap/scss/tooltip', 
    '~bootstrap/scss/popover', 
    '~bootstrap/scss/carousel', 
    // '~bootstrap/scss/spinners', 
    // '~bootstrap/scss/offcanvas', 
    '~bootstrap/scss/helpers', 
    '~bootstrap/scss/utilities/api';
// scss-docs-end import-stack

    
// Animations
// Opt-in loading for animations
// This project mainly deals with in-bound, once per
// page load animations.
// @import 
//     'config/animate',
//     '~animate-sass/_animate.scss';

@import 
    'components/animations';


// Configuration
//@group config
@import 
    'functions',
    'accessibility';

// Mixins 
// Support Functionality (e.g. media queries, button variants, etc.)
@import
  'mixins/accent-circles',
  'mixins/actions',
  'mixins/common',
  'mixins/container',
  'mixins/font',
  'mixins/form',
  'mixins/gradient',
  'mixins/play-button',
  'mixins/position',
  'mixins/scrollbar',
  'mixins/svg-background-image';

// Blocks
@import 
    'blocks/gutenberg';

// WooCommerce
// @devenote moved to own entry
// @import
//     'woo/woocommerce.scss';


// Vendor - 3rd Party Customizations 
// Framework / Plugin Specific styling (e.g. Wordpress, Gravity Forms, WooCommerce, etc.)
@import 'vendor/wp',
    'vendor/gf';

// Glide
@import '~@glidejs/glide/src/assets/sass/glide.core.scss';
// @import 
//     '../../wp-content/themes/usdigital-child/blocks/src/module-video-tabs/client.scss',
//     '../../wp-content/themes/usdigital-child/blocks/src/testimonial-slider/client.scss',
//     '../../wp-content/themes/usdigital-child/blocks/src/feature-slider/client.scss';


// Components 
// Collective BS components (e.g. .carousel, .navbar, .modal)
@import 
    'components/archive',
    'components/blockquote',
    'components/buttons',
    'components/callout',
    'components/carousel',
    'components/collapse',
    'components/footer',
    'components/form-newsletter-signup',
    'components/form',
    'components/gdpr',
    'components/header',
    'components/hero',
    'components/instagram-feed',
    'components/modal',
    'components/nav',
    'components/product',
    'components/related-products',
    'components/shop',
    'components/single',
    'components/tease',
    'components/testimonial';

// Browser overrides
:focus-visible {
    outline-color: $theme-purple;
}