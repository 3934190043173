/// @group PositionHelpers
@mixin position( $pos, $t, $r, $b, $l ) {
	position: $pos;
	top: $t;
	right: $r;
	bottom: $b;
	left: $l;
}
@mixin position-reset {
	@include position( initial, auto, auto, auto, auto );
}