///
/// @group Gutenberg|Cover
///
.wp-block-cover {

    @include media-breakpoint-down(md) {
        padding-left: $spacer;
        padding-right: $spacer;

        min-height: rem(300);
    }

    // defaults
    padding: 4rem 0;

    // embedded
    [class*="__inner-container"] & {
        padding: 3rem 2rem;
        border-radius: $border-radius-lg;
        min-height: 0px;
        &:before {
            border-radius: $border-radius-lg;
        }

        :last-child {
            margin-bottom: 0;
        }
        margin-bottom: 3rem;
    }

    &.is-style-home,
    &.is-style-interior {
        h1 {
            font-size: rem(60);
            line-height: rem(73);
            @include media-breakpoint-down(md) {
                font-size: rem(42);
                line-height: rem(50);
            }
        }
    
        p {
            font-size: rem(32);
            line-height: rem(42);
            @include media-breakpoint-down(md) {
                font-size: rem(21);
                line-height: rem(30);
            }
        }

        margin-bottom: 3rem;
    }

    &.is-style-home {
        justify-content: flex-start;
        .wp-block-cover__inner-container {
            padding-top: 4rem;
            padding-bottom: 6rem;

            .wp-block-group__inner-container > * {
                & + * {
                    margin-top: 1.5rem !important;
                }
            }

            *:not(.wp-block-group):not(.wp-block-group__inner-container):not(.wp-block) {
                max-width: rem(830);
            }
        }
        &:before {
            z-index: 1;
        }
        &:after {
            content: '';
            background-image: url(/assets/images/pages/home-hero-images@2x.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right bottom;

            position: absolute;
            right: 0;
            top: 0;
            bottom: -2rem;
            left: 33vw;

            z-index: 2;
        }
        @include media-breakpoint-down(sm) {
            flex-direction: column;
            padding: 0;

            h1 {
                font-size: rem(24);
                line-height: rem(35);
            }

            p {
                font-size: rem(20);
                line-height: rem(24);
            }

            .wp-block-cover__inner-container {
                padding-bottom: 0;
                margin-bottom: -4rem;
            }

            .accent-circles {
                display: none;
            }

            &:after {
                background-image: url(/assets/images/pages/home-hero-images_mobile@2x.png);
                background-position: center center;
                background-size: cover;
                @include position(relative, auto, auto, auto, auto);
                width: 100%;
                bottom: -2rem;
                padding-top: 100%;
            }
        }

        .wp-block-cover__inner-container {
            z-index: 3;
            position: relative;

            .alignwide {
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }
    }

    &.is-style-interior {
        
    }

    // Why does my nose run section
    &.why-does-my-nose-run {
        background: linear-gradient($theme-purple-light, $theme-purple-light 54.9%, $theme-purple 55%, $theme-purple);
        position: relative;
        overflow: hidden;

        &:before {
            display: none;
        }
        &:after {
            content: '';
            opacity: 1;
            
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            bottom: 0;

            display: block;

            background-color: $theme-purple;
            @include svg-background-image('<svg viewBox="0 0 1177 39" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><mask id="background-mask" fill="#fff"><path d="M0,0 L0,36.15703 C30.159399,37.0756575 63.144824,33.0730663 88.040826,25.4628193 L88.040826,25.4628193 C108.991426,19.0547115 129.531302,10.4206034 155.073428,10.5184087 L155.073428,10.5184087 C172.291957,10.585263 187.872495,14.7178488 202.558022,18.6634901 L202.558022,18.6634901 C248.789601,31.0761038 302.914436,43.3042492 354.863048,36.5036819 L354.863048,36.5036819 C405.25336,29.9086297 444.832437,6.23725791 496.888941,9.50940413 L496.888941,9.50940413 C537.030771,12.0312966 564.32491,30.1351915 604.069503,33.6698027 L604.069503,33.6698027 C635.042994,36.4256852 664.976801,29.8516798 694.65314,23.2764363 L694.65314,23.2764363 C726.704321,16.1775008 758.4588,9.07856536 790.899862,13.7249387 L790.899862,13.7249387 C829.297035,19.2280374 851.452834,33.6598984 891.012295,37.2514596 L891.012295,37.2514596 C930.731141,40.8541631 965.402373,39.6049783 993.229841,26.6699107 L993.229841,26.6699107 C1021.05486,13.7385572 1059.73524,6.26078072 1097.23618,13.034111 L1097.23618,13.034111 C1131.08106,19.1463266 1143.17474,25.3315868 1176.47893,31.986065 L1176.47893,31.986065 C1176.65303,32.0207302 1176.8259,32.0553954 1177,32.0888225 L1177,32.0888225 L1177,0 L0,0 Z"></path></mask><rect fill="#{$theme-purple-light}" mask="url(#background-mask)" x="0" y="0" width="1177" height="39" transform="translate(0,-1)"/></svg>');
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: top center;
        }

        .wp-block-columns {
            margin-top: 6rem;
        }
        .wp-block-column {
            background-color: $white;
            border-radius: $border-radius-lg;
            box-shadow: 0px 0px 4px rgba($black, .1);
            padding: 2rem 1.5rem;

            &:nth-child(odd) {
                transform: translateY(-1rem);
            }
            &:nth-child(even) {
                transform: translateY(1rem);
            }

            @include media-breakpoint-down(md) {
                transform: translateY(0) !important;
                margin-bottom: 2rem;
            }

            :last-child {
                margin-bottom: 0;
            }

            position: relative;
            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                background-size: contain;
                background-repeat: no-repeat;

                width: 3rem;
                height: 3rem;
            }
            // Emojis

            // Cold/Flu
            &:nth-child(1):before {
                background-image: url(/assets/images/emojis/sneeze@2x.png);
                top: -1.5rem;
                left: calc(50% - 1.5rem);
            }

            // Allergies
            &:nth-child(2):before {
                background-image: url(/assets/images/emojis/allergen@2x.png);
                top: 1.5rem;
                right: -1.5rem;
            }
            &:nth-child(2):after {
                background-image: url(/assets/images/emojis/allergen@2x.png);
                bottom: 1.5rem;
                left: -1.5rem;
            }

            // Crying
            &:nth-child(3):before {
                background-image: url(/assets/images/emojis/cry@2x.png);
                bottom: -1.5rem;
                left: calc(50% - 1.5rem);
            }

            // Cold Air
            &:nth-child(4):before {
                background-image: url(/assets/images/emojis/cloud@2x.png);
                top: -1.5rem;
                right: 1.5rem;
            }
            &:nth-child(4):after {
                background-image: url(/assets/images/emojis/cloud@2x.png);
                top: 1.5rem;
                left: 0;
            }

        }
    }

}