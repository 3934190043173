/// @group Form

// Woocommerce
form .form-row {
    display: flex;
    flex-direction: column-reverse;

    // padding: 0 !important;

    label {
        @extend .form-label;
        font-size: rem(16);
        font-weight: normal;
        margin-top: .25rem;
    }

    input:not([type="checkbox"]):not([type="radio"]),
    textarea {
        @extend .form-control;

        border-color: $primary;
        padding: rem(12);
    }
    input[type="checkbox"] {
        margin-right: .25rem;
    }
    select {
        @extend .form-select;
    }

    .select2 {
        &-selection {
            height: rem(48);
            border-radius: $border-radius;
            border: 1px solid $primary;
    
            &__rendered {
                line-height: rem(48) !important;
                padding-left: 12px !important;
            }
            &__arrow {
                height: 100% !important;
                b {
                    left: 35% !important;
                }
            }
        }
    }
}

.select2 {
    &-results {
        &__option {
            &--highlighted {
                background-color: $primary !important;
            }
        }
    }
}