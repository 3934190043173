.newsletter-signup {
	margin: 5rem 0;

	h3 {
		font-size: 3rem;
		color: $theme-purple;
		margin-bottom: 2rem;
	}
	p {
		font-size: rem( 32 );
		line-height: rem( 42 );
	}

	@include media-breakpoint-down(md) {
		h3, p {
			text-align: center;
		}
	}

	#gform_wrapper_1 {
		form {
			// Inputs Styling
			.gform_fields {
				display: flex;
				flex-wrap: wrap;

				.gfield {
					display: block;
					label {
						font-weight: normal;
						font-size: 1rem;

						margin-top: 0;
						margin-bottom: 0rem;

						.gfield_required {
							color: $theme-orange-accent;
						}
					}

					.ginput_container {
						input {
							padding: 0.75rem 1rem !important;

							width: 100%;
							font-size: rem( 20 );

							background-color: $theme-purple-light;
							border-color: transparent;

							border-radius: 0;
						}
					}
				}

				// Name, email fields
				#field_1_1,
				#field_1_2 {
					flex: 0 0 50%;
					width: 50%;
					padding-right: 2rem;
					@include media-breakpoint-down(md) {
						flex: 0 0 100%;
						width: 100%;
						padding-right: 0;
					}
				}

				// DOB fields
				#field_1_3 {
					flex: 0 0 100%;
					width: 100%;
					padding-right: 2rem;
                    .gfield_list_cell {
                        width: 50%;
                        padding-right: 1rem;
                    }
                    .gfield_list_icons {
                        padding-left: 1rem;
                        .add_list_item {
                            color: $black;
                            text-decoration: none;
                            font-family: "Font Awesome 5 Free";

                            display: inline-flex;
                            align-items: center;

                            &:before {
                                content: "\f055";
                                font-weight: 900;
                                font-size: 1.5rem;
                                color: $theme-purple;
                            }
                            
                            &:after {
                                font-family: $font-family-sans-serif;
                                content: 'Add another child';
                                margin-left: 1rem;
                            }

                            img {
                                display: none;
                            }
                        }
                        .delete_list_item {
                            color: $black;
                            text-decoration: none;
                            font-family: "Font Awesome 5 Free";

                            display: inline-flex;
                            align-items: center;

                            margin-left: 1rem;

                            &:before {
                                content: "\f056";
                                font-weight: 900;
                                font-size: 1.5rem;
                                color: $theme-purple;
                            }

                            img {
                                display: none;
                            }
                        }
                        .add_list_item,
                        .delete_list_item {
                            &:before {
                                transition: all .3s;
                            }
                            @include on-action {
                                &:before {
                                    opacity: .5;
                                }
                            }
                        }
                    }
					@include media-breakpoint-down(md) {
						padding-right: 0;
						table {
							tr, th, td {
								border: none !important;
							}
						}
						.gfield_list_group {
							display: flex !important;
							align-items: center;
							flex-wrap: nowrap;
							
							padding-top: 0;
							&:first-child {
								margin-top: 0;
							}

							.gfield_list_cell {
								padding: 0;
								flex: 1 0 auto;
								width: auto;
								margin-left: 0;
								margin-right: 0;
								input {
									margin-left: 0;
								}
							}

							.gfield_list_icons {
								margin: 0 !important;
								flex: 0 1 auto;
								width: auto !important;
								background-color: transparent;
								a {
									margin-left: .5rem;
								}
								&:after,
								a:after {
									display: none;
								}
							}
						}
						.gfield_list_cell {}
						.gfield_list_icons {}
					}
					&.gfield_error {
						flex-wrap: wrap;
						> label {
							width: 100%;
							flex: 0 0 100%;
							margin: 0.5rem 0;
						}
					}
				}
			}
		}
	}
}
