@mixin accent-circles() {
    position: relative;
    > * {
        position: relative;
        z-index: 2;
    }
    .accent-circles {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}