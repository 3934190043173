// @single.twig
.single {

    // single post
    .post {
        &-content {
            margin: 4rem auto;

            @include media-breakpoint-down(sm) {
                margin-top: 2rem;
            }

            > .container > * {
                margin-bottom: 2rem;
            }

            iframe {
                max-width: 100%;
            }

            img {
                max-width: 100%;
                height: auto;
            }
        }

        &-header {
            h1 {
                font-size: rem(48);
                line-height: rem(73);

                margin-top: 2rem;

                @include media-breakpoint-down(lg) {
                    font-size: rem(36);
                    line-height: rem(48);
                }
                @include media-breakpoint-down(md) {
                    font-size: rem(24);
                    line-height: rem(36);
                }
            }

            .wp-block-cover {
                padding-bottom: 0;
                @include media-breakpoint-down(lg) {
                    padding: 2rem 0 !important;
                    min-height: 0px;
                }
            }

            .wp-block-columns {
                margin-bottom: 0;
                .wp-block-column + .wp-block-column {
                    margin-left: 5rem;
                }
                @include media-breakpoint-down(lg) {
                    align-items: center;
                    .wp-block-column {
                        & + .wp-block-column {
                            margin-left: 2rem;
                        }
                    }
                }
                @include media-breakpoint-down(sm) {
                    flex-direction: column-reverse;
                    padding: 0 2rem;
                    .wp-block-column {
                        width: 100%;
                        flex-basis: 100% !important;
                        margin: 0 !important;

                        .post-image {
                            margin-top: 2rem;
                            margin-bottom: -50vw;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            &-share {
                padding-top: 45vw;
                .nav.nav--social {
                    justify-content: center;
                    .nav-item {
                        .nav-link {
                            font-size: rem(18) !important;
                            padding: .25rem .75rem;
                        }
                    }
                }
            }
        }

        &-image {
            display: block;

            border-radius: 2rem;
            overflow: hidden;
            padding-top: 100%;

            position: relative;

            margin-bottom: -4rem;

            @include media-breakpoint-down(lg) {
                margin-bottom: 0;
            }

            img {
                @include position(absolute,0,0,0,0);
                width: 100%;
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
                object-position: center center;
                margin-bottom: 0;
            }
        }
    }
}
