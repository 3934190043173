///
/// @group Hero
///
.hero {
    text-align: center;

    padding-top: 4rem;
    padding-bottom: 3rem;

    @include accent-circles;

    h1 {
        font-size: rem(48);
        line-height: rem(73);
        margin-bottom: 3rem;
    }

    p {
        font-size: rem(32);
        line-height: rem(39);
    }

    @include media-breakpoint-down(md) {
        h1 {
            font-size: rem(29);
            line-height: rem(44);
            margin-bottom: 1rem;
        }
        p {
            font-size: rem(19);
            line-height: rem(24);
        }
    }

    @include media-breakpoint-up(md) {
        padding-top: 5rem;
        padding-bottom: 4rem;
        
       .container > * {
           max-width: rem(830);
           margin-left: auto;
           margin-right: auto;
       }
    }

    &--shop {
        h1 {
            color: $theme-purple;
        }
    }
}